export const Component = function UIComponents() {
  return (
    <Flex
      vertical
      gap={16}
    >
      <Divider>BzAction 操作按钮</Divider>
      <Row gutter={16}>
        <Col span={4}></Col>
      </Row>

      <Divider>{`BzActions 操作按钮组`}</Divider>
      <Row gutter={16}>
        <Col span={4}></Col>
      </Row>

      <Divider>{`BzPopover 操作按钮组`}</Divider>
      <Row gutter={16}>
        <Col span={4}></Col>
      </Row>
    </Flex>
  );
};
